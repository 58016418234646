<template>
	<BaseButton v-if="cell && cell.onClick" :type="cellType" @click="cell.onClick">
		{{ cell.label }}
	</BaseButton>
	<BaseButton v-else-if="cell && cell.href" :type="cellType" :href="cell.href">
		{{ cell.label }}
	</BaseButton>
	<span v-else-if="cell" v-html="cell.label"></span>
</template>

<script>
	import BaseButton from './BaseButton';

	export default {
		components: {
			BaseButton
		},
		props: {
			cell: {
				type: Object,
				required: false
			}
		},
		computed: {
			cellType() {
				return this.cell.type || 'primary';
			}
		}
	};
</script>

<style lang="scss" scoped>
	.button:not(:first-child) {
		margin-left: 10px;
	}
</style>