<template>
	<main>
		<h1>Submissions</h1>
		<ResponsiveTable ref="table" :headers="['User', 'Course', 'Started', 'Score/Progress', 'Certificate', '']" :rows="submissions" @page="updateSubmissions" />

		<nav>
			<BaseButton v-if="page > 1" @click="previous()">
				<IconArrowLeft width="20" height="20" />
			</BaseButton>
			<BaseButton @click="next()">
				<IconArrowRight width="20" height="20" />
			</BaseButton>
		</nav>
	</main>
</template>

<script>
	import {roleGuard} from '../../inc/auth';
	import Backend from '../../inc/backend';
	import ResponsiveTable from '../../components/ResponsiveTable.vue';
	import {DateTime} from 'luxon';
	import IconArrowLeft from '../../components/icons/IconArrowLeft.vue';
	import IconArrowRight from '../../components/icons/IconArrowRight.vue';
	import BaseButton from '../../components/BaseButton.vue';
	import {handleFileDownload} from '../../inc/utils';

	export default {
		...roleGuard('Courses', 'any', 'teacher'),
		components: {
			ResponsiveTable,
			IconArrowLeft,
			IconArrowRight,
			BaseButton
		},
		props: {
			courseId: {
				type: String,
				required: true
			},
			classId: {
				type: String,
				required: true
			}
		},
		data() {
			return {
				page: 1,
				submissions: []
			};
		},
		watch: {
			page(page) {
				this.updateSubmissions(page);
			}
		},
		created() {
			this.updateSubmissions();
		},
		methods: {
			next() {
				this.page++;
			},
			previous() {
				this.page = Math.max(1, this.page - 1);
			},
			resetProgress(session) {
				this.$modal.show('dialog', {
					title: 'Reset progress?',
					text: 'This will permanently reset the users progress and delete all submitted answers. Do you want to proceed?',
					buttons: [
						{
							title: 'Cancel',
							handler: () => {
								this.$modal.hide('dialog');
							}
						},
						{
							title: 'Reset',
							class: 'vue-dialog-button danger',
							handler: () => {
								Backend.delete(`sessions/${session}`).then(() => this.updateSubmissions());

								this.$modal.hide('dialog');
							}
						}
					]
				});
			},
			downloadCertificate(sessionId) {
				Backend.get(`/sessions/${sessionId}/download-certificate`, {responseType: 'blob'})
					.then(handleFileDownload);
			},
			updateSubmissions(page) {
				const params = {page: page || 1, course: this.courseId};

				if(this.classId) {
					params.class = this.classId;
				}

				return Backend.get('sessions', {params})
					.then(res => {
						const {sessions} = res.data;

						this.submissions = sessions.map(submission => {
							let scoreLabel = 'N/A';
							let certificateButton = {label: 'N/A'};

							if(submission.maxScore !== null && submission.progress !== null) {
								scoreLabel = `${submission.score || 0} of ${submission.maxScore} / ${submission.progress}%`;
							}

							if(submission.timeEnded) {
								certificateButton = {label: 'Download', onClick: () => this.downloadCertificate(submission.sessionId)};
							}

							return [
								{label: (submission.user ? (submission.user.firstName + ' ' + submission.user.lastName) : 'Unknown')},
								{label: submission.courseName},
								{label: DateTime.fromMillis(submission.timeStarted).toFormat('yyyy-MM-dd')},
								{label: scoreLabel},
								certificateButton,
								[
									{label: 'Reset', onClick: () => this.resetProgress(submission.sessionId), type: 'secondary'},
									{label: 'View', href: {name: 'AdminSubmissionGroups', query: {sessionId: submission.sessionId}}}
								]
							];
						});
					});
			}
		}
	};
</script>

<style lang="scss" scoped>
	nav::v-deep {
		display: flex;
		gap: 20px;
		justify-content: center;

		button {
			padding: 0.25rem 0.5rem;
		}
	}
</style>