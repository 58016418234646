<template>
	<div ref="table" :class="classes">
		<table>
			<thead>
				<tr>
					<th v-for="(header, index) in headers" :key="index">
						{{ header || '&nbsp;' }}
					</th>
				</tr>
			</thead>
			<tbody v-if="rows.length" @click="toggleRow">
				<tr v-for="(row, i) in rows" :key="i">
					<td v-for="(col, j) in row" :key="j" :data-th="formatHeader(headers[j])" :class="colClasses(col)">
						<IconChevron v-if="j === 0" :width="16" :height="16" class="collapse-arrow" />

						<div v-if="Array.isArray(col)">
							<ResponsiveTableCell v-for="(cell, k) in col" :key="k" :cell="cell" />
						</div>
						<ResponsiveTableCell v-else :cell="col" />
					</td>
				</tr>
			</tbody>
			<tbody v-else>
				<tr>
					<td :colspan="headers.length">
						<center>No data yet.</center>
					</td>
				</tr>
			</tbody>
		</table>

		<nav class="pagination">
			<ul v-if="pages > 1">
				<li v-if="currentPage > 1" aria-label="Previous" role="button" @click="emitPage(currentPage - 1)">
					<IconArrowLeft :width="12" :height="12" />
				</li>
				<li v-for="page in pages" :key="page" :class="currentPage === page ? 'active' : ''" @click="emitPage(page)">
					{{ page }}
				</li>
				<li v-if="currentPage < pages" aria-label="Next" role="button" @click="emitPage(currentPage + 1)">
					<IconArrowRight :width="12" :height="12" />
				</li>
			</ul>
		</nav>
	</div>
</template>

<script>
	import {debounce} from 'vue-debounce';
	import IconChevron from './icons/IconChevron.vue';
	import IconArrowRight from './icons/IconArrowRight.vue';
	import IconArrowLeft from './icons/IconArrowLeft.vue';
	import ResponsiveTableCell from './ResponsiveTableCell.vue';

	export default {
		components: {
			IconChevron,
			IconArrowLeft,
			IconArrowRight,
			ResponsiveTableCell
		},
		props: {
			headers: {
				type: Array,
				required: true
			},
			rows: {
				type: Array
			},
			pages: {
				type: Number,
				default: 0
			}
		},
		data() {
			return {
				collapseTable: false,
				currentPage: 1
			};
		},
		computed: {
			classes() {
				return 'responsive-table' + (this.collapseTable ? ' collapse' : '');
			}
		},
		created() {
			const dFn = this.onResize();

			dFn();

			window.addEventListener('resize', dFn);
		},
		destroyed() {
			window.removeEventListener('resize', this.onResize());
		},
		methods: {
			onResize() {
				return debounce(() => {
					this.collapseTable = false;

					setTimeout(() => {
						this.collapseTable = this.$refs.table.scrollWidth > this.$refs.table.clientWidth;
					});
				}, 100);
			},
			formatHeader(str) {
				return str.length ? str + ':' : '';
			},
			toggleRow(e) {
				if(!this.collapseTable) {
					return;
				}

				const prevOpen = this.$refs.table.querySelectorAll('.open');
				const element = e.target.closest('tr');

				if(element) {
					element.classList.toggle('open');
				}

				if(prevOpen && prevOpen.length) {
					prevOpen.forEach(tr => tr.classList.remove('open'));
				}
			},
			colClasses(col) {
				if(Array.isArray(col)) {
					for(const cell of col) {
						if(cell.href || cell.onClick) {
							return 'cta';
						}
					}
				}

				return col.href || col.onClick ? 'cta' : '';
			},
			emitPage(page) {
				if(page === this.currentPage || page < 1 || page > this.pages) {
					return;
				}

				this.currentPage = page;

				this.$emit('page', page);
			}
		}
	};
</script>

<style lang="scss" scoped>
	.responsive-table {
		max-width: 100%;
		overflow: auto;

		.collapse-arrow {
			display: none;
		}

		&.collapse table {
			thead {
				display: none;
			}

			.collapse-arrow {
				display: block;
			}

			tr {
				td {
					display: none;
					padding-left: $default_padding * 2;
					overflow: hidden;
					border-width: 0 0 1px;

					&:first-child {
						display: block;
						position: relative;
						background-color: $color__white;
						cursor: pointer;

						svg {
							position: absolute;
							top: 50%;
							left: 15px;
							transform: translateY(-50%) rotate(180deg);
							transition: transform .2s ease;
						}
					}

					&:last-child {
						padding-bottom: .5em;
					}

					& > span {
						margin-left: 5px;
					}

					&:not(.cta):before {
						content: attr(data-th);
						font-weight: bold;
						display: inline-block;
					}
				}

				&.open td {
					display: block;

					&.cta > div {
						justify-content: flex-start;
					}

					&:first-child svg {
						transform: translateY(-50%);
					}
				}
			}
		}

		table {
			min-width: 300px;
			max-width: none;
			width: 100%;
			height: fit-content;
			border-collapse: collapse;

			th,
			td {
				&:first-child {
					padding-left: $site__padding * 2;
				}

				&:last-child {
					padding-right: $site__padding * 2;
				}
			}

			thead {
				text-transform: uppercase;
				letter-spacing: 0.05em;
				font-weight: $font__bold;

				th,
				td {
					background: rgba($color__white, 0.75);
					border-top: $border;
					border-bottom: $border;
				}
			}

			tbody {
				td {
					border-bottom: $border;
					border-right: $border;
					border-left: none;
					border-top: none;
				}

				td::before {
					display: none;
				}

				td:last-child {
					border-right: none;
				}
			}

			tr:nth-child(2n) {
				th,
				td {
					background: rgba($color__white, 0.5);
				}
			}

			.button > * {
				line-height: 1.75em;
				padding: 0 0.75em;
			}
		}
	}

	.pagination {
		padding: 0 80px;

		ul {
			display: inline-flex;
			flex-flow: row nowrap;
			justify-content: center;
			border-radius: $border_radius;
			border: $border;
			background-color: $color__white;
			padding: 0;
		}

		li {
			line-height: 2em;
			padding: 0 1em;

			&.active {
				font-weight: bold;
			}

			&:not(.active) {
				cursor: pointer;

				&:hover {
					background-color: $color__semi_dark;
				}
			}
		}
	}
</style>